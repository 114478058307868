import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 120"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <text
        xmlSpace="preserve"
        style={{
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "55.7236px",
          lineHeight: 1.25,
          fontFamily: "sans-serif",
          fill: "#969696",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 1.39309,
        }}
        x={424.843}
        y={100.756}
        transform="translate(-1213.838 -176.283)scale(2.918)"
      >
        <tspan
          x={424.843}
          y={100.756}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 400,
            fontStretch: "normal",
            fontSize: "55.7236px",
            fontFamily: "&quot",
            InkscapeFontSpecification: "&quot",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            strokeWidth: 1.39309,
            fill: "#969696",
            fillOpacity: 1,
          }}
        >
          {"?"}
        </tspan>
      </text>
    </g>
  </svg>
);
export default SVGComponent;
