import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 92.485"
    {...props}
  >
    <defs>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="a"
        x={0}
        width={1}
        y={0}
        height={1}
      >
        <feGaussianBlur />
      </filter>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <g
        style={{
          fill: "#d5f8ff",
          fillOpacity: 1,
          stroke: "none",
        }}
        className="background"
      >
        <path
          style={{
            fill: "#d5f8ff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: ".264583px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M423.088-133.635c-4.054 5.76 5.18 15.598.801 21.115-8.462 10.66-30.055-1.445-40.09 7.75-9.564 8.764-13.167 24.685-10.69 37.418 2.156 11.09 9.978 23.639 20.846 26.727 9.87 2.804 18.663-9.601 28.865-10.69 16.287-1.74 32.968 11.698 48.643 6.948 7.669-2.323 13.356-9.442 17.908-16.036 5.206-7.543 11.18-16.583 9.888-25.658-1.72-12.083-18.017-17.66-22.183-29.132-3.987-10.982 7.977-28.326-1.604-35.012-15.018-10.482-41.844 1.593-52.384 16.57"
          transform="translate(-269.635 116.617)scale(.72414)"
        />
        <path
          style={{
            fill: "#d5f8ff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: ".264583px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M377.92-132.298c-1.448 3.796.35 8.87 3.206 11.76 3.647 3.69 9.78 4.212 14.967 4.276 4.97.061 13.242 1.083 14.433-3.742 1.27-5.143-9.7-5.81-11.76-10.69-2.01-4.764 5.036-12.958.535-15.502-7.4-4.183-18.355 5.954-21.382 13.898m120.539-6.682c9.799-3.912 29.526 7.277 26.46 17.372-2.952 9.719-24.195 7.72-30.47-.267-3.617-4.605-1.43-14.934 4.01-17.105m3.207 82.319c-1.835 4.627 6.08 8.548 10.69 10.423 6.35 2.026 8.268-15.628 15.502-15.234 16.797.915 8.994-16.688 3.742-21.916-3.654-3.637-10.973-.23-15.234 2.673-7.767 5.29-10.791 14.196-14.7 24.054"
          transform="translate(-269.635 116.617)scale(.72414)"
        />
      </g>
      <g className="character">
      <path
        style={{
          fill: "#f6e1b2",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m390.437-138.719-.373 7.712 3.538 3.91 6.64-5.449.117-7.685z"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          opacity: 1,
          fill: "#1addff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M390.342-136.386c-.23.41 2.755 4.757 3.284 5.156.632-.195 6.29-5.98 6.679-6.267.537-.398 3.842 10.991 3.842 10.991l-8.586 18.843-6.549-.1-3.407-21.683c1.537-2.303 1.53-4.637 4.737-6.94"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#00a3c0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M393.626-131.23c-1.28-1.648-2.533-3.3-3.284-5.156 0 0-1.22 1.269-1.698 2.56-.334.902 2.164 4.544 2.94 4.61.598.052 2.042-2.014 2.042-2.014s1.548 2.125 1.967 2.215c.481.103 4.878-4.61 5.278-5.613.401-1.002-.583-2.97-.583-2.97z"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#00b5d5",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
          filter: "url(#a)",
        }}
        d="M388.224-135.625c-.812.535-2.723 2.52-3.914 2.986-2.481.97-6.165.855-8.047 1.716-4.116 1.883-6.16 18.883-6.026 23.828.133 4.944.267 8.018 3.207 8.953 2.94.936 3.764 1.095 5.606 1.048.19 5.622-2.976 19.135-3.638 21.686 2.646 2.362 5.89 5.961 10.017 5.197 2.551-.472 4.206-7.979 4.913-14.174 1.086 6.751 4.914 13.418 5.859 14.174s16.442-1.512 20.033-4.535c-.284-3.119-4.647-18.24-4.914-24.38-.094-2.173.378-4.063 1.512-5.764 1.134-1.7 5.67-12.473 5.953-15.686s-.85-9.071-1.7-10.016c-.851-.945-3.97-.19-6.143-.945-2.173-.756-7.12-3.897-7.782-4.747s-.71-1.184-1.278-1.278c-.567-.095-1.594-.036-1.594-.036s1.487 6.448-.518 11.794c-2.004 5.345-6.548 16.437-6.548 16.437s-1.336.267-2.272-2.272c-.935-2.54-3.34-12.294-3.207-14.834.134-2.539.639-6.6.993-7.545s1.606-2.368 1.606-2.368c-.694.197-1.49.375-2.118.761"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#f6e1b2",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m384.39-150.861.047 1.417c-1.938-.85-.804 2.788-.378 3.307.425.52 1.228 1.323 1.606 1.843s.378 2.835 2.173 4.441c1.796 1.607 3.26 2.788 5.481 2.93 2.22.141 5.292-.473 6.378-1.418s2.266-2.283 2.835-3.307.85-1.89.803-2.552c1.682-.072 3.945-3.515 3.768-4.623-.2-1.002-1.403-1.57-1.653-1.537-.251.034-2.634-4.943-2.634-4.943l-10.962-4.488-6.473 6z"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#1addff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m379.003-73.424.284 10.773 33.356-.095v-13.796l-22.3-7.843z"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#f6e1b2",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M392.917-110.016c.424-.628 4.701-4.63 5.764-4.985 1.064-.354 3.284-.401 4.23-.354.944.047 4.393.59 4.63.921.236.33.07.756-.142.921-.213.166-6.213-.165-6.71-.236 3.072 0 5.67 1.583 5.883 1.96.212.379.401 1.442-.142 1.584-.543.141-1.134-.024-1.134-.024.378.59.378 2.008-.52 1.984-.897-.023-1.393-.189-1.393-.189s.661.615.448.969c-.212.354-1.488.614-2.48.236s-3.26-.141-3.874-.472-1.276-1.04-2.126-1.134c-1.887-.528-3.03-.298-2.434-1.181"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#00a3c0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M378.562-126.16c-1.476 7.957-1.092 9.81-1.092 9.81l.624.021c.034-2.051-.132-5.683.468-9.831"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#f6e1b2",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m384.77-111.806-2.306 5.345s-1.637-.334-1.97-.534c-.335-.2-1.838-2.44-2.64-2.84-.368-1.67-.434-7.016-.434-7.016s1.804-.234 2.438.134c.635.367 3.49 2.484 4.912 4.91"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#9a6000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M386.185-152.42c-.374 1.334-1.496 3.955-2.268 2.55-.772-1.403-1.323-5.196-.945-6.708s3.118-6.52 5.48-6.71c2.363-.188 3.78-2.362 6.71-2.362 2.929 0 7.465 1.89 9.071 4.064 1.606 2.173 3.874 3.307 3.59 4.535-.283 1.229-1.039 1.607-.66 2.268.377.662.259 3.48-.142 5.61-.85-.526-1.536-1.18-2.008-.802-.473.377-1.347 1.334-1.725 1.24s.662-1.89-.378-3.497-1.89-1.795-3.307-1.133-3.213-.662-4.252-2.08c-1.04-1.417-1.55-2.802-3.686-2.362-2.135.44-5.107 4.052-5.48 5.386"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      <path
        style={{
          fill: "#00a3c0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M409.455-119.164s2.276-4.116 3.006-6.443c-1.941 2.64-2.4 3.607-3.137 4.582-.123 1.141.131 1.861.131 1.861m-19.113 34.779s-1.648-5.423-2.433-13.226c-.652 2.154.172 6.682 1.002 9.89.303 1.171 1.431 3.336 1.431 3.336m3.969 21.734s-.193-5.583-2.362-7.843c1.506 3.953 2.362 7.843 2.362 7.843"
        transform="translate(-227.283 129.363)scale(.72817)"
      />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
