import React from "react";

import SVGCircle from './SVG/Circle.jsx';
import SVGLogo from './SVG/logo.jsx';
import { Link } from "react-router-dom";


// onClick={() => {navigator.clipboard.writeText(this.state.textToCopy)}} to copy text to clipboard

export default function Mail(props) {
    let pieceJointe = <></>

    if (props.mail.fichier) {
        if (props.groupe.pdg != "Mysterious"){
            if (typeof props.mail.fichier == "object") {
                pieceJointe = (<>
                    {props.mail.fichier.map((key,i) => (
                    <div className="col-12 pj">
                        🔗 Pièce jointe : <a href={"./fichiers/" + props.groupe.pdg + "/" + key} target="_blank" download={props.groupe.entreprise + " " + props.mail.nomfichier[i]}>{props.mail.nomfichier[i]}</a>
                    </div>))}
                    </>)
            }else {
                pieceJointe = (<div className="col-12 pj">
                    🔗 Pièce jointe : <a href={"./fichiers/" + props.groupe.pdg + "/" + props.mail.fichier} target="_blank" download={props.groupe.entreprise + " " + props.mail.nomfichier}>{props.mail.nomfichier}</a>
                </div>)
            }
        } else {
            pieceJointe = <div className="col-12 pj"><Link to={"/"}>Choisir un PDG pour afficher ce fichier</Link></div>
        }
    }

    let text = props.mail.texte
    let exp = props.mail.exp
    if (props.mail.replace != undefined) {
        for (let [key,value] of Object.entries(props.mail.replace)) {
            text = text.replace(new RegExp(key, 'g'), value[props.groupe.pdg]);
            exp = exp.replace(new RegExp(key, 'g'), value[props.groupe.pdg]);
        }
    }

    return(
    <div>
        <div className="row mail-header">
            
            <div className="col-3 text-center">
                {props.mail.action ?
                <SVGLogo className="logo" logo={props.groupe.pdg} />
                : <SVGCircle className="logo" color={props.color} text={props.mail.exp.charAt(0)}/>}
            </div>
            <div className="col-9">
                <div className="row">
                    <div className="col-7">
                        <p>Objet : <strong>{props.mail.obj}</strong></p>
                    </div>
                    <div className="col-5">
                        <p>De : {props.mail.action ? (props.groupe.pdg + "@" + props.groupe.entreprise + ".com").toLowerCase() : exp}</p>
                    </div>
                    <div className="col-7">
                        <p>Le : {props.mail.date}</p>
                    </div>
                    <div className="col-5">
                        <p>À : Vous{! props.mail.action ? ", "+ props.groupe.pdg : ""}</p>
                    </div>
                    {pieceJointe}
                </div>
            </div>
        </div>
        <div className="mail-text">
            <p dangerouslySetInnerHTML={{__html : text}}></p>
        </div>
    </div>
    );
}