import React, {useState} from 'react';
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";

import Mail from './Mail.jsx'
import SVGCircle from './SVG/Circle.jsx';
import SVGCharacter from "./SVG/character.jsx";

const alphColor = {
   "A":"#0a4831",
   "B":"#cb6b46",
   "C":"#0970e6",
   "D":"#5168cc",
   "E":"#f29408",
   "F":"#e4b700",
   "G":"#1cd489",
   "H":"#30ab9d",
   "I":"#bc6763",
   "J":"#93e838",
   "K":"#e657c0",
   "L":"#77a400",
   "M":"#298de0",
   "N":"#739ffc",
   "O":"#2b98b4",
   "P":"#110a8b",
   "Q":"#73609e",
   "R":"#d1b83f",
   "S":"#2c8e92",
   "T":"#4eeff2",
   "U":"#4ab0d1",
   "V":"#2a4c85",
   "W":"#7c3981",
   "X":"#896967",
   "Y":"#8ca547",
   "Z":"#bd3c4f"}


const SideNav = (props) => {
   const [selected, setSelected] = useState(0)

   return (
   <PanelGroup autoSaveId="example" direction="horizontal">
      <Panel defaultSizePercentage={25} minSize={15} maxSize={50}>
         <div className="sidenav">
            {props.mails.map((mail,ind) =>
               <SideElem {...props} mail={mail} id={ind} selected={selected} setSelected={setSelected}/>)}
         </div>
      </Panel>
      <PanelResizeHandle defaultSizePercentage={75}/>
      <Panel>
         <Mail {...props} mail={props.mails[selected]} color={alphColor[props.mails[selected].exp[0]]}/>
      </Panel>
 </PanelGroup>
   );
};

const SideElem = (props) => {
   let contact;

   if (props.mail.action) {
      contact = <SVGCharacter className="illustration" character={props.groupe.pdg} viewBox="30 6 60 86"/>
   } else {
      contact = <SVGCircle className="logo" color={alphColor[props.mail.exp[0]]} text={props.mail.exp[0]}/>
   }

   let txt;
   txt = props.mail.texte;
   if (props.mail.replace != undefined) {
      for (let [key,value] of Object.entries(props.mail.replace)) {
         txt = txt.replace(new RegExp(key, 'g'), value[props.groupe.pdg]);
      }
  } 


   return (
      <button
         className={props.selected==props.id ? "mail row selected" : "mail row" }
         onClick={() => (props.setSelected(props.id))}>
         {/* <View style={styles.container} /> */}
         <div className='container-logo col-3'>
            {contact}
         </div>
         
         <div className='col-9'>
            <div className='first-row'>
               <p className='exp'>{props.mail.action ? "🚩 " + props.groupe.pdg : props.mail.exp}</p>
               <p className='code'>{props.mail.date}</p>
            </div>
            <p className="object">{props.mail.obj}</p>
            <p className="msg" dangerouslySetInnerHTML={{__html : txt}} />
         </div>
      </button>
   )
}

export default SideNav;