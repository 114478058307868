import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 92.485"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <g className="background">
      <path
        style={{
          fill: "#efe8d2",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".207341px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M27.552 32.262c8.66-7.922 15.08-13.405 27.857-12.148S68.604 30.796 80.96 32.681s20.526 3.56 24.296 11.31-1.517 17.144 2.304 23.458c6.102 10.086 21.438 24.665 5.236 30.37-15.709 5.236-19.897 6.962-31.626-1.546S48.706 93.84 42.632 97.82s-25.133 12.357-31.417 6.283c-6.283-6.074-11.52-13.614-8.587-22.41 2.932-8.797 17.803-1.676 23.249-13.824s-11.73-15.08-8.797-23.458 1.813-4.226 10.472-12.148"
        transform="translate(0 -13.757)"
      />
      <path
        style={{
          fill: "#efe8d2",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".207341px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M6.817 50.693c5.63.457 16.079 7.01 14.033 11.1-2.095 4.19 3.77 8.17-2.304 9.007-6.074.838-9.904-1.343-10.053-6.912-.15-5.568-9.635-3.35-8.378-8.168s1.072-5.483 6.702-5.027M84.312 13.83c-3.595.816-6.53 6.909-4.608 10.054 1.677 2.743 7.068 2.354 9.635.419 1.433-1.08 1.822-3.533 1.257-5.236-.859-2.588-3.625-5.84-6.284-5.236"
        transform="translate(0 -13.757)"
      />
      </g>
      <g className="character">
      <path
        style={{
          fill: "#baa787",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M503.18-111.597s.688 5.377-2.503 7.748l12.615.189s-2.747-.115-3.166-8.315z"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          fill: "#ffef90",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M500.763-105.17c2.021.935 9.955.884 11.492-.017 2.083 13.012 3.049 24.834 2.239 37.835 0 0-13.364 1.336-15.1 0-1.738-1.336-.418-36.591-.418-36.591z"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          opacity: 1,
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M493.78-69.09c8.687 1.738 24.59 1.07 29.4-.266 3.074 12.828 2.272 26.759 2.272 26.759h-33.81s-.534-10.322.134-15.4 2.004-11.092 2.004-11.092"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          fill: "#523600",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M494.582-117.264s-2.075.5-1.418 2.643c.272.886.993 1.18 1.985.661-1.701 2.315 2.646 4.347 4.11 1.89s.615-3.26.615-3.26l-3.024-2.74z"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          fill: "#baa787",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M516.933-126.552s.507 7.384-.501 11.058c-.63 2.295-2.426 4.475-4.588 5.472-2.595 1.198-6.209 1.255-8.575-.073-2.118-1.189-4.043-4.062-4.41-5.8-2.742 1.855-3.715-.892-3.642-2.84.084-2.438 2.172-1.403 2.172-1.403l2.472-5.278 13.764-4.076z"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          opacity: 1,
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m502.963-107.594-2.768.018s-2.94 2.005-4.945 2.472c-2.004.468-2.205-.2-3.407.334-1.203.535-10.296 19.722-11.36 26.527-.667 4.276-.46 7.628 2.273 8.82 2.8 1.221 7.147-1.665 10.262-3.15.175 4.397-3.37 11.228-3.247 12.571.08.881 3.943 5.947 5.747 5.145s5.011-11.626 4.81-17.773c-.09-10.792-.425-24.524 2.635-34.964m7.584-.583s3.212-.468 4.08-.134c.87.334 2.74 2.472 4.878 2.606s4.143-.334 4.744.067c.602.4 5.947 7.082 7.216 8.62 1.27 1.536 7.885 8.084 8.954 10.423 1.069 2.338.067 5.746-.668 6.548s-4.878 9.889-5.947 11.091c-2.138-.066-5.813-4.81-6.014-5.813.735-2.873 3.713-6.73 2.874-9.772-1.475-.15-2.74-.05-3.675-.785-.936-.735-2.005-1.603-2.94-1.737-2.205 11.893-.511 15.51 3.474 25.591-3.097 3.934-8.285 5.947-9.087 5.813s-4.143-2.272-5.011-17.573-1.386-30.587-1.793-31.636c-.327-.845-.751-1.639-1.085-3.31"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          fill: "#baa787",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M490.33-80.32c.094 2.362 1.18 5.575 2.031 6.19 2.599-2.6 5.056-4.725 6.473-5.292s5.575-3.402 6.237-4.063c.661-.662.378-1.37.189-1.56-.19-.189-.945-.047-1.099.024.116-.095.764-.79.417-1.716-.163-.434-1.034-.428-1.338-.378.31-.327 1.162-2.243-1.123-1.878 1.032-1.142-.208-1.908-.732-1.74-.916.293-4.583 2.381-5.575 3.42.945-1.606 1.607-2.126 1.181-3.26s-1.275-1.275-1.606-.944c-.33.33-1.465 2.55-1.843 3.07s-.992 3.639-.992 4.962-.708 2.74-2.22 3.165m39.286 6.308c2.196 1.015 3.425 3.26 3.638 3.945-.969 1.346-3.615 4.772-5.009 5.102-1.393.331-2.433-.33-3.07.048-.639.378-1.772 2.055-2.292 2.197s-1.276-.119-1.323-.378c-.047-.26.094-.804-.26-.615s-1.677 1.11-2.22 1.11-.71-.637-.615-.897-.661.024-1.11 0-1.158-.449-1.04-1.205c.119-.756 3.19-3.118 3.922-3.685-1.134.213-1.7-.165-2.055.024s-1.181 1.133-1.56 1.275c-.377.142-1.228-.283-.968-.85s2.613-2.967 2.93-3.142c.425-.236 1.157-.425 1.842-.425s5.41.141 5.859-.024 2.905-1.583 3.33-2.48"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      <path
        style={{
          fill: "#523600",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M517.835-122.075c-.668-4.277-1.203-4.544-4.41-6.548-3.408 3.608-8.82 4.543-12.896 4.476-3.408 5.212-3.14 4.01-3.14 4.01s-1.938-1.003-2.205 1.47-.602 1.403-.602 1.403-2.606-3.408 0-10.892c2.606-7.483 5.48-7.15 9.622-7.416 4.143-.268 9.087.066 12.361 4.343 3.274 4.276 1.27 9.154 1.27 9.154"
        transform="translate(-351.52 118.079)scale(.80607)"
      />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
