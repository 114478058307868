import React from "react";

import links from './link.json'

export default function Mail(props) {

    return(
    <div className="container" style={{padding: "2em 0"}}>
        <h1>Liste des liens utiles</h1>
        <ul>
            {links.map((element) => {
                return (
                <li>
                    <a href={element.link} target="_blank">{element.text}</a>
                </li>)
            })}
        </ul>
    </div>
    );
}