import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 120"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        style={{
          fill: "#8aa029",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M42.228-355.957c-2.116 0-4.31.588-6.269 1.878-5.303 3.493-2.661 8.377-5.39 12.926-2.624 4.372-6.589 3.105-9.25 5.514-5.72 5.173-4.27 10.897-1.287 14.885q.145.192.3.376c-2.533-6.345 3.354-13.973 9.524-13.496 4.31.333 7.085 3.463 10.797 3.354 4.323-.127 8.262-3.846 9.555-4.285.766-.26 1.107-3.287 1.556-4.878 1.654-5.85.468-10.246-1.715-12.864-1.797-2.154-4.727-3.41-7.82-3.41"
        transform="translate(-43.171 931.069)scale(2.6157)"
      />
      <path
        style={{
          fill: "#afcb37",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.265,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M40.648-328.768c-3.43.101-5.992-2.79-9.975-3.099-6.16-.476-12.018 7.795-8.078 13.862 5.775 8.89 23.505 11.28 35.075 2.073 3.044-2.422 4.958-6.69 3.027-10.968-1.45-3.215-4.862-6.333-8.506-6.293-5.219.056-6.556 4.278-11.543 4.425"
        transform="translate(-43.171 931.069)scale(2.6157)"
      />
      <path
        style={{
          fill: "none",
          fillOpacity: 0.365979,
          stroke: "#8aa029",
          strokeWidth: 1.665,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M28.156-327.085c-2.306 1.247-3.007 3.2-2.426 5.717"
        transform="translate(-43.171 931.069)scale(2.6157)"
      />
      <path
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          writingMode: "lr-tb",
          direction: "ltr",
          textOrientation: "mixed",
          dominantBaseline: "auto",
          baselineShift: "baseline",
          textAnchor: "start",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          clipRule: "nonzero",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          isolation: "auto",
          mixBlendMode: "normal",
          colorInterpolation: "sRGB",
          colorInterpolationFilters: "linearRGB",
          solidColor: "#000",
          solidOpacity: 1,
          vectorEffect: "none",
          fill: "#cbdd7c",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 1.765,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          colorRendering: "auto",
          imageRendering: "auto",
          shapeRendering: "auto",
          textRendering: "auto",
          stopColor: "#000",
        }}
        d="M32.045-346.098c-.076.59-.162 1.185-.286 1.78.405-.02.853.022 1.256.14.691.202 1.18.588 1.328.96.392.99.16 2.554-.566 3.895-.727 1.34-1.904 2.375-2.944 2.57-.457.086-1.14-.158-1.74-.693a4.1 4.1 0 0 1-.912-1.204 8.5 8.5 0 0 1-1.6.776 5.6 5.6 0 0 0 1.34 1.747c.873.776 2.015 1.339 3.238 1.109 1.817-.342 3.259-1.785 4.169-3.463s1.335-3.675.656-5.387c-.43-1.086-1.441-1.701-2.473-2.003a5.6 5.6 0 0 0-1.466-.227m14.263 15.1c-.49.33-.983.656-1.496.958a3.7 3.7 0 0 1 .305.661c.23.682.193 1.305-.027 1.64-.586.887-2.001 1.594-3.516 1.767s-3.038-.2-3.793-.94c-.333-.325-.523-1.025-.43-1.823.028-.233.082-.467.152-.692a29 29 0 0 1-1.63-.734 6 6 0 0 0-.273 1.22c-.136 1.16.057 2.42.946 3.29 1.32 1.293 3.33 1.648 5.227 1.432s3.776-1.012 4.79-2.55c.643-.974.569-2.155.225-3.174a5.7 5.7 0 0 0-.48-1.055"
        transform="translate(-43.171 931.069)scale(2.6157)"
      />
    </g>
  </svg>
);
export default SVGComponent;
