import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 92.485"
    {...props}
  >
    <g className="background">
    <path
      style={{
        fill: "#ffefcd",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".233045px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M39.438 144.895c-4.758-17.53 22.797-20.595 24.247-34.605.677-6.539 6.995-18.733-7.768-18.127-16.749.687 6.715-35.99 21.658-28.014 19.332 10.319 54.165-22.027 59.088 8.475 2.246 13.914 57.372 24.05 34.84 40.962-13.448 10.095-28.782 18.705-24.717 28.955 5.414 13.654-11.234 18.966-22.6 18.833s-21.893-11.3-37.195-8.24-43.167 7.92-47.553-8.239"
      transform="translate(-3.865 -22.135)scale(.6808)"
    />
    <path
      style={{
        fill: "#ffefcd",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M170.49 126.346c-6.394-.285-12.829 7.75-12.027 14.967.802 7.216 18.709 8.82 22.184.267 3.474-8.552-4.06-14.963-10.157-15.234M146.799 68.74c2.055 7 8.725 8.812 14.432 9.355 7.082.675 16.037-2.94 19.778-8.018 3.742-5.078-4.81-10.69-7.483-12.829-2.673-2.138-.267-8.553 2.138-14.433s-10.156-13.096-18.442-9.087-11.491 12.656-8.82 15.235c10.366 10.004-4.609 9.542-1.603 19.778m-98.451 33.818c7.216 4.81-1.069 14.165-16.838 20.312s-13.096 18.442-8.285 26.193c4.81 7.75 7.483 19.243.534 19.243s-11.76-7.75-15.234-17.64c-3.475-9.889-4.01-30.736-.267-36.081 9.89-11.08 28.524-19.691 40.09-12.027"
      transform="translate(-3.865 -22.135)scale(.6808)"
    />
    </g>
    <g className="character">
    <path
      style={{
        fill: "#eb7836",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="m175.853-74.556.756 18.521h29.199l.189-21.828c-9.863 3.879-19.925 4.772-30.144 3.307"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#faf8e0",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M185.02-121.023c.382.401-.115 3.396-1.636 6.016.365 2.092 9.385 5.492 14.14-.757 0 0-5.02-1.708-4.85-6.937z"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#f1a073",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M197.786-117.98c-1.454-.033-2.139.318-2.139.318l-7.816 3.395s-2.14.347-2.607-.054-3.63-2.426-3.63-2.426-1.18-.915-3.386.088c-2.205 1.002-2.539 1.47-3.474 2.739-.936 1.27-4.344 10.29-4.344 10.29s-1.137-2.91-1.493-3.807c-.92-2.318-.843-4.027-1.313-6.149-1.556-.304-5.488-.032-8.419.735-.106 2.435.072 3.59.134 4.276.143 1.578 1.29 14.722 3.207 19.044 1.002 2.138 3.608 3.54 5.747 3.207s6.681-2.874 7.683-4.344c.268 2.74-1.57 11.685-1.47 13.765.057 1.175.067 2.673 1.738 3.073.887.213 6.569.687 12.499.797l5.574-.025c4.085-.03 8.406-.953 11.66-2.509.755-1.071 1.262-2.203 1.364-3.568 6.622-4.859 11.6-8.994 12.267-11.8.669-2.806 1.003-1.537.2-4.276-.8-2.74-9.087-17.707-15.234-20.78-3.073-1.537-5.295-1.955-6.748-1.988m10.69 18.76s1.003 3.073.87 4.677c-.05.581-.6 1.62-1.306 2.72z"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#faf8e0",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M160.575-112.424s.428-2.799.027-3.734c-.401-.936-1.504-4.845-1.57-5.814-.067-.968-.268-2.205.3-2.94s.778-.785.96-1.044c.14-.201.243-.392.778-1.36.534-.97 1.236-1.437 2.07-1.337.836.1 1.671.768 1.705 1.102.033.334.635.067.868.034.234-.034 1.938-.502 2.272 1.202s.334 6.615-.835 7.55c-1.17.936-1.27 1.37-1.203 1.972s.283 4.268.283 4.268c-1.944.423-4.146.442-5.655.101m24.255-29.128c-2.976.378-4.214 1.652-4.913 3.354a792 792 0 0 0-2.646 6.568c-.239 1.194-.473 4.37-.168 5.76.24 1.095 1.882 2.482 3.097 1.988 1.229 1.56 3.307 3.024 4.961 3.024s5.882-.709 7.583-1.417 1.441-.85 2.859-2.268-.319-8.739-.615-10.111c-.69-3.203-2.728-5.226-4.299-6.473-1.44-1.144-5.859-.425-5.859-.425"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#f0e235",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M180.2-123.882s-1.89 1.512-3.732.756-3.024-2.126-2.74-3.921c.283-1.796 2.078-3.733 2.456-5.197.378-1.465-.189-5.056.52-7.135s4.063-6.614 7.89-7.701 9.733-.094 12.568 1.606 4.772 5.009 5.055 7.654c.284 2.646-.22 2.991.284 4.583.567 1.796 2.22 2.977 1.795 4.63s-3.26 3.497-3.354 4.064.52.945.52.945c-3.694.17-5.74-.647-6.332-1.748-.661-1.37-.33-5.812-.992-8.6s-1.748-4.677-4.11-6.567-7.182-.189-8.269.945-2.879 5.27-3.009 7.007c-.297 3.967-.246 6.322 1.45 8.68"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#eb7836",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".426652px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M202.016-97.725c1.963 1.904 1.786 17.647.248 18.372-1.418.67-9.346 1.285-11.029 1.074-1.679-.21-2.377-1.069-3.02-1.983-1.078-1.533-.944-16.295.906-18.364 1.148-1.283 10.421-1.155 12.895.9"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#faf8e0",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M205.666-86.84s-1.9 2.172-2.693 2.362c-.747.18-4.254-.671-4.866-.91-.628-.243-3.528-1.432-4.146-.672-.435.534 1.075 2.315 2.894 2.622-1.758.897-3.386 1.204-5.292.874-.874-.166-2.107-.222-2.244.425-.194.915 1.923 1.57 2.622 1.89-.497.323-.916.481-.85.85.075.425.637.71 1.417.945-.33.544-.402.615-.118.827.283.213 1.134.095 1.134.095-.756.26-.182.734-.182.734s.938.045 1.505-.12c.567-.166-.037.145.047.425.07.236.59.33 1.347.33.681 0 5.48-.944 6.968-1.795 1.489-.85 4.607-3.117 4.607-3.117-.177-2.82-1.093-4.204-2.15-5.765"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#eb7836",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".264583px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M186.838-114.078c-1.299 1.772-1.819 2.339-2.551 2.859-1.3-1.205-2.622-4.607-2.693-5.528.68-1.264 1.741-1.244 3.216-1.555-.537 2.194-.058 2.726 2.028 4.224m.992-.189c1.158 1.606 1.749 2.788 2.552 3.166 1.252-1.37 5.445-5.396 5.953-6.284.189-.331-.066-.645-.213-.898-.114-.199-2.302-.997-2.727-1.162-.354 1.228-5.564 5.178-5.564 5.178"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    <path
      style={{
        fill: "#d05a14",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: ".149147px",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeOpacity: 1,
      }}
      d="M189.348-56.035c-.17-1.691.015-3.45.412-5.247-.864 1.648-1.018 3.423-.892 5.247z"
      transform="translate(-95.403 129.673)scale(.81968)"
    />
    </g>
  </svg>
);
export default SVGComponent;
