import React, { useState } from 'react'

// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'
// import { MemoryRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Home'
import SideNav from './SideNav'
import Header from './Header'
import Link from './Link.jsx'

import data from './data.jsx'

// import data from 

const mysteriousGroup = {
    "pdg" : "Mysterious",
    "produit" : "Suspens",
    "entreprise" : "ChanceMaker"
}

export default function App() {
    const [groupe, setGroupe] = useState(mysteriousGroup)

    console.log(data)

    const defGroupe = (id) => {
        if (typeof data.groupes[id] !== "undefined") {
            setGroupe(data.groupes[id])
        } else {
            setGroupe(mysteriousGroup)
        }
    }
    
    return (
    <Router>
        <Header groupe={groupe}/>
        <Routes>
        <Route path="/mail" element={<SideNav width={"25%"} mails={data.mails} groupe={groupe}/>} />
        <Route path="/link" element={<Link />} />
        <Route path="/" element={<Home groupes={data.groupes} setGroupe={defGroupe}/>} />
        </Routes>
    </Router>
    )
}