import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 92.485"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <g className="background">
      <path
        style={{
          fill: "#eef4d6",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M158.54-366.24c9.666-12.794 33.41-23.253 40.626-14.967s-4.32 38.2 16.57 47.306 7.576 29.298 25.926 27.53c18.349-1.77 31.27 28.063 12.561 39.555s-37.017 7.75-57.463 6.415c-20.446-1.337-28.865 3.741-47.306 5.078-18.442 1.336-42.496-1.604-42.229-14.967.267-13.364 31.27-10.424 40.358-22.718s-.268-18.976-6.682-27.796-20.728-11.48-12.027-20.847c8.7-9.368 20.001-11.796 29.667-24.59"
        transform="matrix(-.69935 0 0 .69935 184.188 269.754)"
      />
      <path
        style={{
          fill: "#eef4d6",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M99.474-291.94c-14.645-6.291-5.345-17.372 2.94-24.053s20.045-8.286 21.382.534c.722 4.764 11.46-1.005 15.501 3.207 5.438 5.667 4.277 8.286-.534 16.304s-14.433 9.087-17.907 1.87c-4.438-9.216-6.415-10.156-11.226-6.681-4.94 3.568-4.203 11.377-10.156 8.82m135.773-81.785c-8.085.312-12.562 10.424-11.493 21.382 1.07 10.958 6.95 13.363 14.433 13.898 7.484.534 15.234-4.276 15.234-12.562s-4.276-2.94-9.087-8.552c-3.377-3.94-3.481-14.382-9.087-14.166"
        transform="matrix(-.69935 0 0 .69935 184.188 269.754)"
      />
      </g>
      <g className="character">
      <path
        style={{
          fill: "#faf8e0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M169.806-331.107v8.22l5.67 2.647 5.102-3.308-.283-7.748z"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#cbdd7c",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m175.853-323.075-5.244 3.638 5.622 9.591 5.481-8.457z"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#8aa029",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m157.639-272.763.1 20.134h18.04s1.203-4.432.535-9.109c.668 9.488 1.604 9.109 1.604 9.109h17.105l-.483-21.97z"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#faf8e0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M185.823-344.62c-.116 3.041.379 6.18-.71 8.977-1.056 2.72-2.722 5.707-5.385 6.898-2.594 1.16-6.019.808-8.505-.567-2.349-1.299-4.063-4.441-4.724-6.52-3.213.85-4.253-2.457-4.253-4.158 0-1.7.945-1.984 1.229-1.984.283 0 2.079 0 2.835 1.04-1.701-1.985-2.363-3.97-2.363-3.97s1.323-5.574 6.331-7.275 11.15-2.552 13.985.661 1.649 4.543 1.56 6.898"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#7e4b0f",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M167.443-344.242c-1.014.643-2.011 1.059-1.369 3.07 0 0-1.547-.686-2.42-.568-.875.118-1.041.38-1.207.617-.07-1.441-.359-7.366 2.308-10.618 2.74-3.34 6.748-4.61 9.287-4.744 3.47-.182 5.747 1.07 5.947 1.604 3.389 1.048 6.483 2.742 7.31 5.816.334 1.246 1.026 2.574-.444 4.311-.917-2.687-1.643-3.641-5.062-5.65 0 3.407-12.561 2.472-12.561 2.472s.467.985.334 1.47c-.273.987-1.291 1.694-2.123 2.22"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#8aa029",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M169.05-328.083c1.04 2.551 4.819 4.252 6.803 5.008-2.645 2.74-4.346 4.82-4.346 4.82l-4.536-7.088s.472-1.7 2.079-2.74m6.803 5.008 4.914 5.008 2.835-6.804-2.362-3.212c-.56 1.996-2.363 3.874-5.387 5.008"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#afcb37",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M156.778-272.562c9.206 3.887 31.496 2.906 37.978-.267-.13-4.866 1.983-6.314 2.004-11.36 0 0 5.947.115 8.286-2.271 2.635-2.69.935-10.023 0-12.428-.936-2.406-5.575-20.04-6.816-21.515-1.825-2.171-16.99-7.68-16.99-7.68s2.062 2.187.661 6.803c-.99 3.26-5.67 10.3-5.67 10.3s-7.384-10.234-8.414-15.967c0 0-2.26 1.8-3.797 2.4-1.537.602-9.488 2.674-10.825 4.144s-8.896 22.348-7.35 30.535c1.81 9.586 7.652 5.34 9.622 8.753-.747 4.37 1.516 4.537 1.31 8.553"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#8f9969",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".266604px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M175.626-299.816c3.489-2.562 14.064-2.493 16.193-.19 2.075 2.245-2.118 21.34-5.525 23.033-4.049 2.011-14.51 2.207-16.955-.571-2.701-3.069 3.136-19.958 6.287-22.272"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#faf8e0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M168.964-288.598s-1.202 5.412-.868 6.548c3.675.601 11.044.132 13.65-.536s4.42-1.56 4.607-2.115c.196-.58-.149-.753-.834-.703.77-.254.91-.302 1.553-.789.405-.306.2-1.403-.936-1.203.94-.44 1.293-.81 1.194-1.408-.08-.485-.585-.515-1.394-.329.972-.431 1.26-.523 1.676-.938.352-.35.39-1.953-1.28-1.552-1.67.4-3.87 1.354-5.14 1.555s-4.677.467-4.744.267 2.405-2.205 2.539-2.806c.133-.602.2-1.738-.334-1.738-.535 0-2.205.669-3.742 2.005-.722.628-4.477 3.274-4.878 3.541s-1.069.2-1.069.2"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      <path
        style={{
          fill: "#8aa029",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M157.9-315.043c-1.701 8.788-3.591 18.804-2.835 22.584 3.402 1.134 1.89.661 1.89.661s.094-18.331.945-23.245m35.904.015c2.745 7.98 4.29 15.147 4.629 18.6-1.203 1.37-2.074 1.323-3.161 1.37.085-4.479.4-6.195-1.468-19.97"
        transform="translate(-67.108 266.143)scale(.72202)"
      />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
