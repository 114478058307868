import React from "react"
import { Link } from "react-router-dom";
import SVGCharacter from "./SVG/character.jsx";

export default function Home(props) {
    console.log(props.groupes)
    return (
        <div className='row home-container' style={{margin:'0 10vw 0 10vw'}} >
          {props.groupes.map(
            (groupe, index) => {return(
              <div key={index} className="text-center col-6">
                <Link to="/mail"> 
                    <SVGCharacter character={groupe.pdg} className="illustration" onClick={() => (props.setGroupe(index))}/>
                </Link>
              </div>
            )
            }
          )}
        </div>
    )
}
