import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 92.485"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <g className="background">
      <path
        style={{
          display: "inline",
          fill: "#e0e0e0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".227071px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M418.104 81.477c-.39-2.498 1.658-5.168 3.753-5.177 2.095-.01 1.463 2.442 2.268 3.67s2.562 1.35 3.557.147c.994-1.203 1.247-3.003.606-4.42-.64-1.416-1.14-2.483 1.128-3.903 2.269-1.42 7.777-2.633 10.314.55 2.536 3.184 7.366 6.636 8.473 9.899 1.106 3.263.35 4.077-.524 6.816-.873 2.74-5.71 5.177-8.409 4.428-2.698-.748-2.69-2.031-5.234-2.835-2.542-.803-6.709 1.15-9.3 1.638-2.592.49-4.238-.27-5.291-2.553-1.054-2.282-.951-5.762-1.341-8.26"
        transform="translate(-1213.838 -190.04)scale(2.918)"
      />
      <path
        style={{
          display: "inline",
          fill: "#e0e0e0",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".197907px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M416.063 72.511c-.002.343.372 1.907 1.21 1.987s2.898-.672 3.207-1.585c.31-.913 3.217-3.414 1.697-4.26-1.52-.844-4.801.13-5.642.737-.841.605-.47 2.779-.472 3.121m36.335 5.119c-.093 1.508 1.7 2.4 2.957 1.697 1.906-.583 2.245-2.903 1.074-4.338-1.162-2.29-3.888-2.88-6.19-3.327-1.283-.079-2.61-.885-3.864-.313-1.384.612-2.222 2.682-1.047 3.86 1.62 1.241 3.777-.012 5.564.725.783.25 1.323.909 1.506 1.695"
        transform="translate(-1213.838 -190.04)scale(2.918)"
      />
      </g>
      <g className="character">
      <path
        style={{
          fill: "#969696",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".999999px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m1426.607-233.47-52.945 27.523 2.512 45.205h52.25c.855-5.8 3.034-11.9 3.918-17.707 1.135 6.179 1.242 11.733 2.04 17.707h65.405l-.883-46.254z"
        transform="translate(-236.069 116.671)scale(.20484)"
      />
      <path
        style={{
          fill: "#dbb59f",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m375.296-113.482.016 6.715 4.008 6.358 5.845-4.762-.287-8.97z"
        transform="translate(-236.069 116.671)scale(.7742)"
      />
      <path
        style={{
          fill: "#dbb59f",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M369.25-119.006c1.265 5.418 5.818 8.174 10.4 8.22 4.93.052 8.876-3.801 9.25-6.667.43-3.288.835-11.176.835-11.176l-2.847-3.723-13.563-1.472-4.395 5.943z"
        transform="translate(-236.069 116.671)scale(.7742)"
      />
      <path
        style={{
          fill: "#6d4129",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M375.69-132.789c2.151 4.769 6.489 4.148 6.489 4.148s-.394-.932-.257-1.994c1.58 3.195 5.169 2.415 6.37 1.225 1.092 3.363 1.19 7.738.684 11.165 0 0 2.863-.67 3.007-5.237.083-2.631.153-6.436-1.425-7.644 0 0 .08-3.353-2.92-5.488-2.924-2.083-4.492-.125-4.492-.125s-2.372-3.862-5.966-2.285c-3.593 1.577-4 3.434-4 3.434-2.011.074-5.2 3.392-5.701 6.205-.506 2.832-1.55 7.377 1.772 10.379-.442-4.983.622-6.902 1.878-8.874 2.107-3.31 4.561-4.909 4.561-4.909"
        transform="translate(-236.069 116.671)scale(.7742)"
      />
      <g
        style={{
          stroke: "#d1d1d1",
          strokeOpacity: 1,
        }}
      >
        <path
          style={{
            fill: "#ddd",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: ".264583px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M372.429-124.105s-.049 3.188 2.94 3.202c2.424.01 3.393-1.246 3.333-2.74s-2.383-1.506-3.313-1.51-2.523-.045-2.96 1.048m14.941.008s.05 3.187-2.94 3.2c-2.423.012-3.392-1.245-3.332-2.74.06-1.494 2.383-1.505 3.313-1.51.93-.003 2.523-.044 2.96 1.05"
          transform="translate(-236.069 116.671)scale(.7742)"
          stroke="none"
        />
        <path
          style={{
            fill: "none",
            stroke: "#ddd",
            strokeWidth: ".264583px",
            strokeLinecap: "butt",
            strokeLinejoin: "miter",
            strokeOpacity: 1,
          }}
          d="M378.536-123.512c.898-.427 2.59-.253 2.922-.02"
          transform="translate(-236.069 116.671)scale(.7742)"
        />
      </g>
      <path
        style={{
          opacity: 1,
          fill: "#f4f4f4",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="m372.648-109.503 2.674-1.302s-.068 1.152.07 1.963c.187 1.1 3.564 5.942 4.5 6.463.896-.559 4.912-5.612 5.008-6.08.096-.47.079-2.489.079-2.489l2.552 1.843.837 1.93L380.986-85l-2.818.033-6.945-21.069z"
        transform="translate(-236.069 116.671)scale(.7742)"
      />
      <path
        style={{
          fill: "#dbb59f",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M404.1-97.476s-.073-4.414-.163-5.354-.547-3.571-1.012-4.419c-.466-.847-2.094-3.213-2.284-3.743-.185-.518-.485-1.412.008-1.808.416-.334 1.104-.583 1.808.079.642.604 1.562 2.653 2.455 2.1.754-.465 1.79-1.235 2.241-2.243.437-.977 1.88-5.203 2.256-5.483.377-.28 1.832-.039 2.016.62.185.658-1.525 4.876-1.525 4.876s.967-.164 1.689.43c.424.35.36.984.36.984s.758.166 1.275.762c.408.47.4.875.4.875s.629.045 1.073.4c.586.466.49 1.462.124 1.94-.32.415-1.629 2.343-2.494 3.68-.331.514-1.443 1.5-1.445 1.828s-.493 5.163-.493 5.163z"
        transform="translate(-236.069 116.671)scale(.7742)"
      />
      <path
        style={{
          opacity: 1,
          fill: "#2f2f2f",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".999999px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M1408.432-414.005c-15.02 14.84-38.187 13.064-54.579 20.29-5.701 4.765-32.88 85.923-32.082 105.094s7 21.331 11.78 24.016 40.05 12.787 40.05 12.787-7.866 50.19-8.234 53.027c12.71 14.79 41.482 10.31 45.75 8.377s19.985-22.98 21.96-27.762c3.27 21.668 21.699 27.967 25.066 29.047s42.607-2.997 46.53-7.06c.218-8.518-6.498-52.564-6.802-63.391-.304-10.828.53-37.445.53-37.445s10.397 15.667 18.372 18.011c7.976 2.345 14.904 1.133 20.073-3.634 5.169-4.768 11.813-21.597 12.914-29.223s5.187-46.83 5.248-59.785c-11.368 1.899-27.85-2.97-31.742-5.473-1.221-4.62-7.375-17.07-8.26-17.607s-12.245-.413-17.207-1.856-31.18-11.682-33.114-15.773c-1.855 17.384-31.103 85.754-31.103 85.754s-24.933-61.45-25.15-87.394m-39.181 67.572c3.861 9.247 7.584 47.953 5.798 50.43-1.786 2.475-13.547-3.15-15.238-5.129 1.562-14.565 6.357-31.295 9.44-45.3"
        transform="translate(-236.069 116.671)scale(.20484)"
      />
      </g>
    </g>
  </svg>
);
export default SVGComponent;