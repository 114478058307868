import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 120"
    {...props}
  >
    <g
      transform="translate(-5.272 328.152)scale(1.70668)"
      style={{
        display: "inline",
      }}
    >
      <path
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          writingMode: "lr-tb",
          direction: "ltr",
          textOrientation: "mixed",
          dominantBaseline: "auto",
          baselineShift: "baseline",
          textAnchor: "start",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          clipRule: "nonzero",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          isolation: "auto",
          mixBlendMode: "normal",
          colorInterpolation: "sRGB",
          colorInterpolationFilters: "linearRGB",
          solidColor: "#000",
          solidOpacity: 1,
          vectorEffect: "none",
          fill: "#eb7836",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          colorRendering: "auto",
          imageRendering: "auto",
          shapeRendering: "auto",
          textRendering: "auto",
          stopColor: "#000",
        }}
        d="m63.424-152.55-13.476 1.35c-.83 6.605-1.739 16.62-1.2 24.004.237.83.995 3.506.995 3.506s3.276-.063 6.858 0c6.861.12 6.952-22.503 6.823-28.86"
      />
      <path
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          writingMode: "lr-tb",
          direction: "ltr",
          textOrientation: "mixed",
          dominantBaseline: "auto",
          baselineShift: "baseline",
          textAnchor: "start",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          clipRule: "nonzero",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          isolation: "auto",
          mixBlendMode: "normal",
          colorInterpolation: "sRGB",
          colorInterpolationFilters: "linearRGB",
          solidColor: "#000",
          solidOpacity: 1,
          vectorEffect: "none",
          fill: "#f1a073",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          colorRendering: "auto",
          imageRendering: "auto",
          shapeRendering: "auto",
          textRendering: "auto",
          stopColor: "#000",
        }}
        d="m52.457-149.66-14.737.318.883 25.652h11.883Z"
      />
      <path
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          writingMode: "lr-tb",
          direction: "ltr",
          textOrientation: "mixed",
          dominantBaseline: "auto",
          baselineShift: "baseline",
          textAnchor: "start",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          clipRule: "nonzero",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          isolation: "auto",
          mixBlendMode: "normal",
          colorInterpolation: "sRGB",
          colorInterpolationFilters: "linearRGB",
          solidColor: "#000",
          solidOpacity: 1,
          vectorEffect: "none",
          fill: "#eb7836",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          colorRendering: "auto",
          imageRendering: "auto",
          shapeRendering: "auto",
          textRendering: "auto",
          stopColor: "#000",
        }}
        d="M27.423-150.59a.383.383 0 0 0-.361.404c2.15 4.42 1.847 26.496 1.847 26.496h9.68s.034-18.64-.103-25.668a.383.383 0 0 0-.352-.373l-10.66-.857-.05-.002"
      />
      <path
        style={{
          color: "#000",
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 400,
          fontStretch: "normal",
          fontSize: "medium",
          lineHeight: "normal",
          fontFamily: "sans-serif",
          fontVariantLigatures: "normal",
          fontVariantPosition: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantAlternates: "normal",
          fontVariantEastAsian: "normal",
          fontFeatureSettings: "normal",
          fontVariationSettings: "normal",
          textIndent: 0,
          textAlign: "start",
          textDecoration: "none",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textDecorationColor: "#000",
          letterSpacing: "normal",
          wordSpacing: "normal",
          textTransform: "none",
          writingMode: "lr-tb",
          direction: "ltr",
          textOrientation: "mixed",
          dominantBaseline: "auto",
          baselineShift: "baseline",
          textAnchor: "start",
          whiteSpace: "normal",
          shapePadding: 0,
          shapeMargin: 0,
          inlineSize: 0,
          clipRule: "nonzero",
          display: "inline",
          overflow: "visible",
          visibility: "visible",
          isolation: "auto",
          mixBlendMode: "normal",
          colorInterpolation: "sRGB",
          colorInterpolationFilters: "linearRGB",
          solidColor: "#000",
          solidOpacity: 1,
          vectorEffect: "none",
          fill: "#f1a073",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "none",
          strokeWidth: 0.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
          colorRendering: "auto",
          imageRendering: "auto",
          shapeRendering: "auto",
          textRendering: "auto",
          stopColor: "#000",
        }}
        d="M13.66-149.542c.971 9.646 2.818 22.002 3.605 23.718.415.905 1.293 2.134 4.744 2.134h7.928s-.963-18.833-2.112-26.539z"
      />
      <path
        style={{
          fill: "#ffefcd",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M16.46-173.96c-7.623.173-13.861 7.797-13.341 13.514.52 5.718 7.624 17.5 20.272 10.57 12.129 11.609 26.986 1.862 29.066.216 16.404 10.876 33.674-18.435 7.455-24.684-.49-11.762-31.034-29.863-43.452.384"
      />
      <circle
        style={{
          fill: "#d05a14",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers stroke fill",
          stopColor: "#000",
        }}
        cx={24.973}
        cy={-161.994}
        r={3.921}
      />
      <circle
        style={{
          fill: "#d05a14",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.967852,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers stroke fill",
          stopColor: "#000",
        }}
        cx={42.86}
        cy={-166.597}
        r={4.96}
      />
      <circle
        style={{
          fill: "#d05a14",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.584688,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers stroke fill",
          stopColor: "#000",
        }}
        cx={60.025}
        cy={-160.701}
        r={2.809}
      />
      <circle
        style={{
          fill: "#d05a14",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 6.2,
          strokeDasharray: "none",
          strokeOpacity: 1,
          paintOrder: "markers stroke fill",
          stopColor: "#000",
        }}
        cx={35.177}
        cy={-179.675}
        r={3.431}
      />
    </g>
  </svg>
);
export default SVGComponent;
