const game_data = {
    "mails" : [
        {
            obj : "Présentation",
            exp : "M",
            action : true,
            date : "#0",
            texte : "<p>Bonjour,<br/>Je suis #pdg, PDG de #entreprise, leader sur le marché #produit. Bienvenue dans la nouvelle équipe logistique !<br/>Vous avez carte blanche pour optimiser notre supply chain transport !<br/>Pour chaque étape d'optimisation et modifications que vous souhaitez appliquer, vous aurez à disposition cette messagerie de mail pour vous expliquer le déroulé dans les grandes lignes, ainsi que des fiches tutoriel pour bien utiliser Colivri. Vous pourrez <br/><br/><strong>1 - Paramétrer les décisions à tester <br/>2 - Lancer vos simulations <br/>3 - Analyser les résultats et prendre votre décision <br/>4 - Paramétrer la configuration correspondant à votre décision</strong><br/><br/>Bonne chance !</p>",
            replace : {
                "#pdg": {
                    "Francine":"Francine",
                    "Michelle":"Michelle",
                    "Jack":"Jack",
                    "Adam":"Adam"
                },
                "#entreprise": {
                    "Francine":"GrainFlow",
                    "Michelle":"SugarRoad",
                    "Jack":"LegumeLink",
                    "Adam":"FreshSmile"
                },
                "#produit": {
                    "Francine":"de la farine",
                    "Michelle":"des gâteaux",
                    "Jack":"des légumes",
                    "Adam":"du dentifrice"
                },

            },
            fichier : "JDD.xlsx",
            nomfichier : "Périmètre initial.xlsx"
        },
        {
            obj : "Appel d'offre",
            exp : "M",
            action : true,
            date : "#1",
            texte : "<p>Bonjour,<br/>Vous avez lancé un appel d'offre pour le transport LTL. Voici deux offres de transporteurs différents en pièce jointe de ce mail, que vous pouvez comparer avec votre offre actuelle. Je vous laisse analyser tout ça et choisir la meilleure offre !<br/>Bonne journée,<br/>#pdg</p>",
            replace : {
                "#pdg": {
                    "Francine":"Francine",
                    "Michelle":"Michelle",
                    "Jack":"Jack",
                    "Adam":"Adam"
                }
            },
            fichier : ["tarif2.csv","tarif3.csv"],
            nomfichier : ["FastDrop.csv","SpeedParcel.csv"]
        },
        {
            obj : "Déménagement",
            exp : "M",
            action : true,
            date : "#2",
            texte : "<p>Vous souhaitez déplacer vos entrepots de départ. En plus de vos entrepôts actuels qui sont d'office candidats, voici deux nouveaux sites possibles pour l'expédition de vos flux. Il faudra en choisir uniquement deux parmis ces quatre possibilités ! <center><div class='CTA'><table><tr><td>Entrepôts</td><td>Latitude</td><td>Longitude</td></tr><tr><td>#ent1</td><td>#lat1</td><td>#lon1</td></tr><tr><td>#ent2</td><td>#lat2</td><td>#lon2</td></tr></table></div></center><br/><br/><br/>Bonne journée,<br/>#pdg</p>",
            replace : {
                "#pdg": {
                    "Francine":"Francine",
                    "Michelle":"Michelle",
                    "Jack":"Jack",
                    "Adam":"Adam"
                },
                "#entrepot": {
                    "Francine":"Orléans",
                    "Michelle":"Limoges",
                    "Jack":"Chartres",
                    "Adam":"Brive-la-Gaillarde"
                },
                "#ent1": {
                    "Francine":"Reims",
                    "Michelle":"Périgueux",
                    "Jack":"Melun",
                    "Adam":"Clermont Ferrand"
                },
                "#ent2": {
                    "Francine":"Rouen",
                    "Michelle":"Pau",
                    "Jack":"Château-Thierry",
                    "Adam":"Toulouse"
                },
                "#lat1": {
                    "Francine":"49.191",
                    "Michelle":"45.165",
                    "Jack":"48.539",
                    "Adam":"45.766"
                },
                "#lat2": {
                    "Francine":"49.639",
                    "Michelle":"43.450",
                    "Jack":"49.080",
                    "Adam":"43.593"
                },
                "#lon1": {
                    "Francine":"3.892",
                    "Michelle":"0.783",
                    "Jack":"2.615",
                    "Adam":"3.049"
                },
                "#lon2": {
                    "Francine":"1.308",
                    "Michelle":"-0.495",
                    "Jack":"3.338",
                    "Adam":"1.499"
                },
            }
        },
        {
            obj : "Camion complet",
            exp : "M",
            action : true,
            date : "#3",
            texte : "<p>Mettons en place des camions complets dans notre solution de transport ! Utilisons une offre FTL qui nous permet de faire plusieurs stops durant un trajet. J'ai deux offres identiques qui s'appliquent à des zones différentes, choisissez-en une ! Les deux zones concernées sont :<br/><center><div class='CTA'><h1>Zones des transporteurs</h1><br/>#zone1<br/>#zone2</div></center><br/><br/><br/>Bonne journée,<br/>#pdg</p>",
            replace : {
                "#pdg": {
                    "Francine":"Francine",
                    "Michelle":"Michelle",
                    "Jack":"Jack",
                    "Adam":"Adam"
                },
                "#zone1": {
                    "Francine":"Zone 1: [Ile-de-France]",
                    "Michelle":"Zone 1: [Occitanie]",
                    "Jack":"Zone 1: [Calvados, Seine-Saint-Denis, Seine-Et-Marne]",
                    "Adam":"Zone 1: [Provence-Alpes-Côte d'Azur]"
                },
                "#zone2": {
                    "Francine":"Zone 2: [Hauts-de-France]",
                    "Michelle":"Zone 2: [Gironde et Haute-Garonne]",
                    "Jack":"Zone 2: [Essonne et Val-d'Oise]",
                    "Adam":"Zone 2: [Auvergne-Rhône-Alpes]"
                },
            }
        },
        {
            obj : "Diminution de CO2",
            exp : "M",
            action : true,
            date : "#4",
            texte : "<p>Soyons plus sobre en émission GES, appliquons une offre de transport plus responsable pour notre transport ! Nous avons le choix entre du carburant B100 et HVO, qui augmente le tarif de respectivement 5% et 15%. Vous pouvez appliquer ces modifications sur l'ensemble de vos tarifs !<br/>La planète nous remerciera ! <br/><br/>Bonne journée,<br/>#pdg</p>",
            replace : {
                "#pdg": {
                    "Francine":"Francine",
                    "Michelle":"Michelle",
                    "Jack":"Jack",
                    "Adam":"Adam"
                }
            }
        },
        {
            obj : "Ajouts de hubs",
            exp : "M",
            action : true,
            date : "#5",
            texte : "<p>Que pensez-vous de faire passer nos livraisons par des hubs ? Il y a dans notre schéma de transport, 2 hubs possibles, choisissez le meilleur ! Voici les coordonnées des hubs :<br/><center><div class='CTA'><table><h1>Hubs</h1><tr><td>Dép. du hub</td><td>Latitude</td><td>Longitude</td></tr><tr><td>#hub1</td><td>#lat1</td><td>#lon1</td></tr><tr><td>#hub2</td><td>#lat2</td><td>#lon2</td></tr></table></div></center><br/><br/><br/>Bonne journée,<br/>#pdg</p>",
            replace : {
                "#pdg": {
                    "Francine":"Francine",
                    "Michelle":"Michelle",
                    "Jack":"Jack",
                    "Adam":"Adam"
                },
                "#hub1": {
                    "Francine":"Val d'Oise",
                    "Michelle":"Hérault",
                    "Jack":"Eure",
                    "Adam":"Loire"
                },
                "#hub2": {
                    "Francine":"Pas-de-Calais",
                    "Michelle":"Gironde",
                    "Jack":"Yvelines",
                    "Adam":"Gard"
                },  
                "#lat1": {
                    "Francine":"49.052",
                    "Michelle":"44.0579",
                    "Jack":"49.078",
                    "Adam":"45.537"
                },
                "#lat2": {
                    "Francine":"50.457",
                    "Michelle":"44.949",
                    "Jack":"48.9156",
                    "Adam":"44.099"
                },
                "#lon1": {
                    "Francine":"2.970",
                    "Michelle":"3.682",
                    "Jack":"0.867",
                    "Adam":"4.456"
                },
                "#lon2": {
                    "Francine":"2.570",
                    "Michelle":"-0.087",
                    "Jack":"1.938",
                    "Adam":"4.564"
                },
            }
        },
        {
            obj : "Changement de volume",
            exp : "Kari Duclos",
            action : false,
            date : "#V1",
            texte : "<p>Suite au succès de votre produit, le marché est en hausse !<br/>Le dépôt de <strong>#client</strong> modifie le volume des livraisons de #produit à la hausse, à hauteur de <strong>+5%</strong>. Veuillez prendre en compte et appliquer ce changement dès que possible.<br/><br/>Bien cordialement,<br/>Monsieur Duclos</p>",
            replace : {
                "#client": {
                    "Francine":"ITM Canly",
                    "Michelle":"ITM Montauban",
                    "Jack":"ITM Louviers",
                    "Adam":"ITM La Valentine"
                },
                "#produit": {
                    "Francine":"farine",
                    "Michelle":"gâteaux",
                    "Jack":"légumes",
                    "Adam":"dentifrice"
                }
            }
        },
        {
            obj : "Changement de volume",
            exp : "Pierre Lamothe",
            action : false,
            date : "#V2",
            texte : "<p>Suite au succès de votre produit, le marché est en hausse !<br/>Le dépôt de <strong>#client</strong> modifie le volume des livraisons de #produit à la hausse, à hauteur de <strong>+8%</strong>. Veuillez prendre en compte et appliquer ce changement dès que possible.<br/><br/>Bien cordialement,<br/>Monsieur Lamothe</p>",
            replace : {
                "#client": {
                    "Francine":"Leclerc Scapnor Bruyeres",
                    "Michelle":"Leclerc Clermont Ferrand",
                    "Jack":"Leclerc Garosud",
                    "Adam":"Leclerc Avignon"
                },
                "#produit": {
                    "Francine":"farine",
                    "Michelle":"gâteaux",
                    "Jack":"légumes",
                    "Adam":"dentifrice"
                }
            }
        },
        {
            obj : "Changement de volume",
            exp : "Marveille Laframboise",
            action : false,
            date : "#V3",
            texte : "<p>Suite au succès de votre produit, le marché est en hausse !<br/>Le dépôt de <strong>#client</strong> modifie le volume des livraisons de #produit à la hausse, à hauteur de <strong>+8%</strong>. Veuillez prendre en compte et appliquer ce changement dès que possible.<br/><br/>Bien cordialement,<br/>Madame Laframboise</p>",
            replace : {
                "#client": {
                    "Francine":"Lidl Lillers",
                    "Michelle":"Lidl Pau",
                    "Jack":"Lidl Meaux",
                    "Adam":"Lidl Nimes"
                },
                "#produit": {
                    "Francine":"farine",
                    "Michelle":"gâteaux",
                    "Jack":"légumes",
                    "Adam":"dentifrice"
                }
            }
        },
        {
            obj : "Changement de volume",
            exp : "Kari Duclos",
            action : false,
            date : "#V4",
            texte : "<p>Suite à la crise en cours, le marché est en récession.<br/>Le dépôt de <strong>#client</strong> modifie les livraisons de #produit à la baisse, à hauteur de <strong>-10%</strong>. Veuillez prendre en compte et appliquer ce changement dès que possible.<br/><br/>Bien cordialement,<br/>Monsieur Duclos</p>",
            replace : {
                "#client": {
                    "Francine":"ITM Canly",
                    "Michelle":"ITM Montauban",
                    "Jack":"ITM Louviers",
                    "Adam":"ITM La Valentine"
                },
                "#produit": {
                    "Francine":"farine",
                    "Michelle":"gâteaux",
                    "Jack":"légumes",
                    "Adam":"dentifrice"
                }
            }
        },
        {
            obj : "Changement de volume",
            exp : "Pierre Lamothe",
            action : false,
            date : "#V5",
            texte : "<p>Suite à la crise en cours, le marché est en récession.<br/>Le dépôt de <strong>#client</strong> modifie les livraisons de #produit à la baisse, à hauteur de <strong>-15%</strong>. Veuillez prendre en compte et appliquer ce changement dès que possible.<br/><br/>Bien cordialement,<br/>Monsieur Lamothe</p>",
            replace : {
                "#client": {
                    "Francine":"Leclerc Scapnor Bruyeres",
                    "Michelle":"Leclerc Clermont Ferrand",
                    "Jack":"Leclerc Garosud",
                    "Adam":"Leclerc Avignon"
                },
                "#produit": {
                    "Francine":"farine",
                    "Michelle":"gâteaux",
                    "Jack":"légumes",
                    "Adam":"dentifrice"
                }
            }
        },
        {
            obj : "Changement de volume",
            exp : "Marveille Laframboise",
            action : false,
            date : "#V6",
            texte : "<p>Suite à la crise en cours, le marché est en récession.<br/>Le dépôt de <strong>#client</strong> modifie les livraisons de #produit à la baisse, à hauteur de <strong>-8%</strong>. Veuillez prendre en compte et appliquer ce changement dès que possible.<br/><br/>Bien cordialement,<br/>Madame Laframboise</p>",
            replace : {
                "#client": {
                    "Francine":"Lidl Lillers",
                    "Michelle":"Lidl Pau",
                    "Jack":"Lidl Meaux",
                    "Adam":"Lidl Nimes"
                },
                "#produit": {
                    "Francine":"farine",
                    "Michelle":"gâteaux",
                    "Jack":"légumes",
                    "Adam":"dentifrice"
                }
            }
        },
        {
            obj : "Déménagement client",
            exp : "Hedvige Mercier",
            action : false,
            date : "#DEM1",
            texte : "<p>Bonjour,<br/>Le magasin #client se relocalise pour un emplacement plus stratégique. Serait-il possible de prendre en compte cette nouvelle adresse de livraison ? Voici les coordonnées géographique de ce nouveau site :<br/><br/><center><div class='CTA'><h1>#client</h1><br/><table><tr><td>Latitude</td><td>#lat</td></tr><tr><td>Longitude</td><td>#lon</td></tr></table></div></center><br/>Cordialement</p>",
            replace : {
                "#client": {
                    "Francine":"Leclerc Scadif Reau",
                    "Michelle":"Lidl Toulouse Roques",
                    "Jack":"LCM Carpiquet",
                    "Adam":"LCM Valence"
                },
                "#lat": {
                    "Francine":"48.682",
                    "Michelle":"43.488",
                    "Jack":"49.156",
                    "Adam":"44.975"
                },
                "#lon": {
                    "Francine":"2.703",
                    "Michelle":"1.43",
                    "Jack":"-0.26",
                    "Adam":"4.922"
                }
            }
        },
        {
            obj : "Déménagement client",
            exp : "Hugues Arcouet",
            action : false,
            date : "#DEM2",
            texte : "<p>Bonjour,<br/>Le magasin #client se relocalise pour un emplacement plus stratégique. Serait-il possible de prendre en compte cette nouvelle adresse de livraison ? Voici les coordonnées géographique de ce nouveau site :<br/><br/><center><div class='CTA'><h1>#client</h1><br/><table><tr><td>Latitude</td><td>#lat</td></tr><tr><td>Longitude</td><td>#lon</td></tr></table></div></center><br/>Cordialement</p>",
            replace : {
                "#client": {
                    "Francine":"Auchan St Sauveur",
                    "Michelle":"Lidl Pau",
                    "Jack":"Carrefour Fleury",
                    "Adam":"Carrefour St-Etienne"
                },
                "#lat": {
                    "Francine":"49.927",
                    "Michelle":"43.341",
                    "Jack":"48.564",
                    "Adam":"45.407"
                },
                "#lon": {
                    "Francine":"2.376",
                    "Michelle":"-0.456",
                    "Jack":"2.378",
                    "Adam":"4.347"
                }
            }
        },
        {
            obj : "Déménagement client",
            exp : "Gauthier Marcheterre",
            action : false,
            date : "#DEM3",
            texte : "<p>Bonjour,<br/>Le magasin #client se relocalise pour un emplacement plus stratégique. Serait-il possible de prendre en compte cette nouvelle adresse de livraison ? Voici les coordonnées géographique de ce nouveau site :<br/><br/><center><div class='CTA'><h1>#client</h1><br/><table><tr><td>Latitude</td><td>#lat</td></tr><tr><td>Longitude</td><td>#lon</td></tr></table></div></center><br/>Cordialement</p>",
            replace : {
                "#client": {
                    "Francine":"Lidl Sailly Cambrai",
                    "Michelle":"Auchan Perigueux",
                    "Jack":"Leclerc Nanteuil",
                    "Adam":"Auchan Thoiry"
                },
                "#lat": {
                    "Francine":"50.161",
                    "Michelle":"45.218",
                    "Jack":"49.136",
                    "Adam":"46.231"
                },
                "#lon": {
                    "Francine":"3.324",
                    "Michelle":"0.715",
                    "Jack":"2.814",
                    "Adam":"5.962"
                }
            }
        },
        {
            obj : "Déménagement client",
            exp : "Joseph Boisvert",
            action : false,
            date : "#DEM4",
            texte : "<p>Bonjour,<br/>Le magasin #client se relocalise pour un emplacement plus stratégique. Serait-il possible de prendre en compte cette nouvelle adresse de livraison ? Voici les coordonnées géographique de ce nouveau site :<br/><br/><center><div class='CTA'><h1>#client</h1><br/><table><tr><td>Latitude</td><td>#lat</td></tr><tr><td>Longitude</td><td>#lon</td></tr></table></div></center><br/>Cordialement</p>",
            replace : {
                "#client": {
                    "Francine":"Lidl Paris Barbery",
                    "Jack":"Lidl Honguemare",
                    "Michelle":"Leclerc Clermont Ferrand",
                    "Adam":"Auchan Cabries"
                },
                "#lat": {
                    "Francine":"49.221",
                    "Jack":"49.341",
                    "Michelle":"45.754",
                    "Adam":"43.427"
                },
                "#lon": {
                    "Francine":"2.722",
                    "Jack":"0.807",
                    "Michelle":"3.075",
                    "Adam":"5.421"
                }
            }
        },
        {
            obj : "Déménagement client",
            exp : "Esmeraude Houle",
            action : false,
            date : "#DEM5",
            texte : "<p>Bonjour,<br/>Le magasin #client se relocalise pour un emplacement plus stratégique. Serait-il possible de prendre en compte cette nouvelle adresse de livraison ? Voici les coordonnées géographique de ce nouveau site :<br/><br/><center><div class='CTA'><h1>#client</h1><br/><table><tr><td>Latitude</td><td>#lat</td></tr><tr><td>Longitude</td><td>#lon</td></tr></table></div></center><br/>Cordialement</p>",
            replace : {
                "#client": {
                    "Francine":"Auchan Carvin",
                    "Jack":"Lidl Meaux",
                    "Michelle":"Carrefour Villeneuve Les Beziers",
                    "Adam":"Lidl Vallauris"
                },
                "#lat": {
                    "Francine":"50.459",
                    "Jack":"48.947",
                    "Michelle":"43.344",
                    "Adam":"43.579"
                },
                "#lon": {
                    "Francine":"2.876",
                    "Jack":"2.943",
                    "Michelle":"3.167",
                    "Adam":"6.96"
                }
            }
        },
        {
            obj : "Nouveau marché",
            exp : "Carrefour",
            action : false,
            date : "#NM1",
            texte : "Carrefour serait heureux d'étendre sa colaboration avec vous, en incluant notamment cinq nouveaux sites de livraisons. Vous trouverz le jeu de donnée en pièce jointe.<br/>A notre longue collaboration ! -- A VENIR PROCHAINEMENT",
            fichier : "JDD.xlsx",
            nomfichier : "périmètre initial.xlsx"
        },
        {
            obj : "Nouveau marché",
            exp : "Auchan",
            action : false,
            date : "#NM2",
            texte : "Carrefour serait heureux d'étendre sa colaboration avec vous, en incluant notamment cinq nouveaux sites de livraisons. Vous trouverz le jeu de donnée en pièce jointe.<br/>A notre longue collaboration ! -- A VENIR PROCHAINEMENT",
            fichier : "JDD.xlsx",
            nomfichier : "périmètre initial.xlsx"
        }
    ],
    groupes : [
        {
            pdg : "Francine",
            produit : "Farine",
            entreprise : "GrainFlow",
            couleurPrimaire : "#"
        },
        {
            pdg : "Michelle",
            produit : "Biscuits",
            entreprise : "SugarRoad",
            couleurPrimaire : "#"
        },
        {
            pdg : "Jack",
            produit : "Légumes",
            entreprise : "LegumeLink",
            couleurPrimaire : "#"
        },
        {
            pdg : "Adam",
            produit : "Dentifrice",
            entreprise : "FreshSmile",
            couleurPrimaire : "#"
        }
    ]
}

export default game_data;