import React from "react";
import SVGFrancine from "./francine_logo.jsx";
import SVGAdam from "./adam_logo.jsx";
import SVGJack from "./jack_logo.jsx";
import SVGMichelle from "./michelle_logo.jsx";
import SVGMysterious from "./mysterious_logo.jsx";


export default function chooseLogo(props){
    switch (props.logo){
        case "Francine":
            return <SVGFrancine {...props}/>
            break;
        case "Michelle":
            return <SVGMichelle {...props}/>
            break;
        case "Adam":
            return <SVGAdam {...props}/>
            break;
        case "Jack":
            return <SVGJack {...props}/>
            break;
        default:
            return <SVGMysterious {...props}/>
            break;
        }
};