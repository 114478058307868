import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 120"
    {...props}
  >
    <defs>
      <filter
        style={{
          colorInterpolationFilters: "sRGB",
        }}
        id="a"
        x={0}
        width={1}
        y={0}
        height={1}
      >
        <feGaussianBlur />
      </filter>
    </defs>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        style={{
          fill: "#1addff",
          fillOpacity: 1,
          stroke: "#fff",
          strokeWidth: 1,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeDashoffset: 0,
          strokeOpacity: 1,
        }}
        d="M502.475-137.435c-4.82 0-5.641-3.401-10.177-3.401s-9.733 3.401-9.733 9.732c0 6.166 5.765 29.2 11.623 29.2 4.725 0 1.685-14.472 8.355-14.472 6.386 0 2.984 14.457 8.275 14.333 4.631-.108 10.962-24.147 10.774-28.494-.234-5.38-3.403-9.407-9.26-9.354-5.293.047-12.532 2.879-16.997 7.202"
        transform="translate(-1445.09 423.833)scale(2.99769)"
      />
      <path
        style={{
          fill: "#fff",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 2.0261,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
          filter: "url(#a)",
        }}
        d="M485.359 92.656c0-.58 4.248.056 5.712-1.484s1.014-5.98 1.602-5.98c.645-.001.091 4.337 1.524 5.761s5.083 1 4.946 1.601c-.137.6-2.905-.43-4.886 1.247s-1.073 6.037-1.584 6.037-.23-4.76-1.567-6.149c-1.338-1.39-5.747-.451-5.747-1.033"
        transform="rotate(17.668 767.458 -1962.535)scale(1.47954)"
      />
    </g>
  </svg>
);
export default SVGComponent;
