import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    viewBox="0 0 120 120"
    {...props}
  >
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M449.28-122.41c-7.74 1.055-18.214 4.084-19.146 12.84 8.5.828 17.816-3.259 19.146-12.84"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.265,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M428.415-110.145c2.6.773 3.398 1.026 4.805 1.317.27-3.89.135-10.392-1.476-13.954-1.61-3.561-6.623-6.57-8.894-7.746s-3.989-1.704-3.989-1.704 3.373 7.227 3.372 13.605c0 5.402 4.835 8.082 6.182 8.482"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.265,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M426.094-109.28c2.506 1.036 3.275 1.37 4.644 1.804.668-3.842 1.203-10.323-.033-14.031s-5.913-7.217-8.052-8.62-3.792-2.105-3.792-2.105 2.612 7.535 1.956 13.88c-.555 5.373 3.979 8.535 5.277 9.072"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M426.196-93.188c1.195-8.497.707-18.382-12.431-23.01-2.619 13.654 2.406 22.764 12.431 23.01"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M425.261-90.582c2.551-12.095 3.024-13.702 7.56-16.82s13.196-3.185 16.126-2.43c.472 5.576-7.433 13.864-10.362 15.092-2.93 1.229-8.977 4.158-13.324 4.158"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M425.827-92.208c1.27-8.486.869-18.375-12.227-23.119-2.74 13.63 2.205 22.785 12.227 23.119"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M424.184-79.054c.094-7.181-3.969-14.74-7.465-17.67s-6.898-3.307-9.639-3.78c-.09 11.846 3.802 24.663 17.104 21.45"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M421.345-55.988c-8.152-4.276-15.769-17.64-16.704-25.39 9.488-1.47 17.907 8.018 18.308 10.69.4 2.673-1.604 14.7-1.604 14.7"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M422.024-62.937c1.336-10.557 8.686-17.205 24.321-17.339-.133 16.571-16.704 21.482-24.321 17.34"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M425.79-90.053c2.552-12.095 3.024-13.701 7.56-16.82s12.662-3.118 15.591-2.362c.473 5.575-6.898 13.796-9.827 15.024-2.93 1.229-8.977 4.158-13.324 4.158m-4.974 34.595c-8.152-4.277-15.769-17.64-16.704-25.391 9.488-1.47 17.907 8.018 18.307 10.69.401 2.673-1.603 14.7-1.603 14.7"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M424.184-78.525c.094-7.181-3.969-14.74-7.465-17.67s-6.898-3.307-9.639-3.78c-.09 11.846 3.802 24.664 17.104 21.45"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M423.655-79.054c.24-8.204 3.58-11.411 7.857-13.817 4.276-2.405 13.263-1.704 18.208.435-3.608 7.884-9.522 11.325-13.263 12.528-3.742 1.202-8.9 1.575-12.802.854m-1.429 26.733c5.372-11.494 11.452-14.012 23.205-13.58-.03 11.63-10.492 18.315-23.205 13.58"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M422.553-50.781c5.078-11.626 11.092-14.3 22.852-14.165.267 11.626-10.023 18.575-22.852 14.165"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#e4b700",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M420.75-47.941c.667-12.429-1.605-11.76-5.747-14.834-4.143-3.074-8.286-2.138-11.76-2.272-.066.605-.11 1.18-.043 1.54.377 1.997 4.554.224 9.636 2.498 2.708 1.212 7.98 5.985 7.913 13.068"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M420.682-47.04c.668-12.427-1.603-11.76-5.746-14.833s-8.285-2.138-11.76-2.272c1.07 12.027 9.488 14.032 12.428 14.433s3.876 1.336 5.078 2.673"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "none",
          stroke: "#e4b700",
          strokeWidth: 2.765,
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        d="M421.484-31.003c-1.336-3.074 1.128-61.693 10.92-85.036"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M444.5-147.033c-9.071 6.048-9.827 10.017-11.717 13.796-1.499 2.998-1.422 9.736-1.137 14.32.076 1.226.209 2.682.759 2.878.758.271 1.987-1.132 2.786-1.852 4.882-4.398 9.53-8.5 11.01-13.267 1.7-5.48.189-11.15-1.701-15.875"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
      <path
        style={{
          fill: "#ffda00",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: ".264583px",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeOpacity: 1,
        }}
        d="M449.28-122.41c-7.684 1.404-18.295 4.785-18.83 13.573 8.529.444 17.934-3.942 18.83-13.572M425.483-78.43c-1.319-8.063 2.282-11.506 6.558-13.912 4.276-2.405 12.562-1.87 17.506.268-3.608 7.884-8.82 11.492-12.561 12.695-3.742 1.203-7.6 1.67-11.503.949m-3.639 16.117c1.337-10.557 8.86-17.333 24.496-17.467-.134 16.57-16.878 21.61-24.496 17.467"
        transform="translate(-375.844 150.273)scale(1.02203)"
      />
    </g>
  </svg>
);
export default SVGComponent;
