import React from "react";


const SVGCircle = (props) => (
    <svg
      viewBox="0 0 172.35715 172.35715"
      id="svg8"
      {...props}
    >
      <defs id="defs2" />
      <g id="layer1" transform="translate(-22.678566,-13.60714)">
        <circle
          style={{
            opacity: 1,
            fill: props.color,
            strokeWidth: 0.0499999,
          }}
          id="path833"
          cx={108.85714}
          cy={99.785713}
          r={86.178574}
        />
        <text
          xmlSpace="preserve"
          style={{
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "101.101px",
            lineHeight: 1.25,
            fontFamily: "sans-serif",
            fill: "#ffffff",
            fillOpacity: 1,
            stroke: "none",
            strokeWidth: 2.52753,
          }}
          x={107.99324}
          y={133.94679}
          id="text839"
        >
          <tspan
            id="tspan837"
            x={107.99324}
            y={133.94679}
            style={{
              fontStyle: "normal",
              fontVariant: "normal",
              fontWeight: "bold",
              fontStretch: "normal",
              fontFamily: "sans-serif",
              InkscapeFontSpecification: "'sans-serif Bold'",
              textAlign: "center",
              textAnchor: "middle",
              fill: "#ffffff",
              strokeWidth: 2.52753,
            }}
          >
            {props.text}
          </tspan>
        </text>
      </g>
    </svg>
)

export default SVGCircle;